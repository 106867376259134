import { API } from '@stoplight/elements';
import '@stoplight/elements/styles.min.css';
import './App.css';
import specs from './specs/suppliers.json';
import Navbar from './components/Navbar';


function App() {
  return (
    <div className="App">
      <Navbar />
      <div className="Content">
        <API apiDescriptionDocument={specs} logo="" />
      </div>
    </div>
  );
}

export default App;
