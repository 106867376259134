import '../App.css';

function Navbar() {
  return (
    <div className="Navbar">
      <div className="NavbarContent">
        <img className="Logo" src="/logo.svg" alt="logo"/> 
        <div className="Menu">
          <a href="https://docs.axiomdata.io/docs">Docs</a>
          <a href="https://docs.axiomdata.io/guides">User Guides</a>
          <a href="/"><span>API</span></a>
          <a href="https://docs.axiomdata.io/changelog">Changelog</a>    
        </div>   
      </div>    
    </div>
  );
}

export default Navbar;